<template>
  <div>
    <div class="cardTitle">住户信息</div>
    <div class="search-box">
      <a-row>
        <a-col :span="20">
          <a-space size="large">
            <a-select
              v-model="searchForm.sex"
              style="width: 200px"
              placeholder="请选择性别"
            >
              <a-select-option :value="1">男</a-select-option>
              <a-select-option :value="2">女</a-select-option>
              <a-select-option :value="3">保密</a-select-option>
            </a-select>
            <a-input
              v-model="searchForm.keyword"
              style="width: 200px"
              placeholder="请输入关键字（姓名/手机号）"
            />
            <a-button type="primary" @click="getData">查 询</a-button>
            <a-button @click="reset">重 置</a-button>
          </a-space>
        </a-col>
      </a-row>
    </div>
	<div class="search-box">
		<a-button class="add-btn" @click="show.add = true">添加住户</a-button>
		<a-upload name="file" :action="`${$residentUpload}`"
            accept=".xls,.XLS,.xlsx,.XLSX" :showUploadList="false"
        	:headers="uploadHeaders" @change="changeFile">
            <a-button><a-icon type="upload" /> 批量导入</a-button>
        </a-upload>
		<a-button @click="modelDownload">
			<a-icon type="copy" />模版下载
		</a-button>
	</div>
    <div class="main">
      <!-- 表格 -->
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination"
        :scroll="{ x: 1400 }"
        @change="handleTableChange"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: selectionChoosed,
        }"
        :row-key="
          (record, index) => {
            return record.id;
          }
        "
      >
	  	<span slot="build" slot-scope="text, row">
			<div v-for="(item,index) in row.residentListEstateVoList" :key="index">
				{{item.manageBuildingName}}-{{item.manageUnitName}}-{{item.floorLocation}}-{{item.manageEstateName}}
			</div>
		</span>
		<span slot="ids" slot-scope="text, row">
			<a-tag v-for="(item,index) in row.residentListEstateVoList" :key="index">
				{{item.identity==3?'租户':item.identity==1?'业主':item.identity==2?'业主亲属':'租户亲属'}}
			</a-tag>
		</span>
		<span slot="type" slot-scope="text, row">
			<div v-for="(item,index) in row.residentListEstateVoList" :key="index">
				{{item.manageEstateTypeName}}
			</div>
		</span>
        <span slot="action" slot-scope="text, row">
          <a-space>
            <a class="ant-dropdown-link" @click="houseManage(row)">房屋管理</a>
            <a class="ant-dropdown-link" @click="edit(row)">编辑</a>
            <a
              class="ant-dropdown-link"
              @click="(show.in = true), (inform.residentId = row.id)"
              >迁入</a
            >
          </a-space>
        </span>
      </a-table>
    </div>
	<!-- 新增住户 -->
    <add-form
      :show="show.add"
      :editId="editId"
      @close="close"
      @success="success"
    ></add-form>
	<!-- 迁入提交 -->
    <a-modal title="迁入" :visible="show.in" @ok="insubmit" @cancel="inclose">
      <div>
        <a-form-model
          ref="ruleForm"
          :model="inform"
          :rules="rules"
          layout="vertical"
        >
          <a-form-model-item label="关联房屋" prop="estateId">
            <a-cascader
              :options="estateData"
              v-model="estateArr"
              :field-names="{
                label: 'name',
                value: 'id',
                children: 'childList',
              }"
              placeholder="楼栋/单元/房间号"
              @change="onChange"
              style="width: 90%"
            />
          </a-form-model-item>
          <a-form-model-item label="住户身份" prop="identity">
            <a-select
              v-model="inform.identity"
              placeholder="请选择住户身份"
              style="width: 90%"
            >
              <a-select-option
                v-for="item in options.identity"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="房屋租户"
            prop="linkUserId"
            v-if="inform.identity === 4"
          >
            <a-select
              v-model="inform.linkUserId"
              placeholder="请选择住户身份"
              style="width: 90%"
            >
              <a-select-option
                v-for="item in options.tenant"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
	<!-- 住户房屋管理 -->
    <a-drawer
      title="住户详情"
      :width="1000"
      :visible="show.detail"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    >
      <div class="drawer-content">
		  <a-avatar :size="64" icon="user" :src="imgUrl" />
		  <span>
			{{holderDetail.name}}
			{{holderDetail.sex == 1 ? '男' : (holderDetail.sex == 2)?'女':'保密'}}
		  </span>
		  <img style="height: 81px;width: 131px;margin-left: 24px;float: right" :src="idCardFront" />
		  <img style="height: 81px;width: 131px;float: right" :src="idCardBack" />
		<a-divider></a-divider>
		<a-descriptions layout="vertical">
			<a-descriptions-item label="手机号">
				{{holderDetail.tel}}
			</a-descriptions-item>
			<a-descriptions-item label="身份证号"	>
				{{holderDetail.idCard}}
			</a-descriptions-item>
			<a-descriptions-item label="入住时间">
				{{holderDetail.checkTime}}
			</a-descriptions-item>
		</a-descriptions>
        <div style="margin-top: 24px">房屋信息</div>
        <a-divider></a-divider>
        <a-table
          :columns="columns_house"
          :data-source="table_house"
          :pagination="false"
          :row-key="
            (record, index) => {
              return index;
            }
          "
        >
          <span slot="name" slot-scope="text, row">
            {{
              row.manageBuildingName +
              "栋/" +
              row.manageUnitName +
              "单元/" +
              row.floorLocation +
              "层-" +
              row.manageEstateName +
              "室"
            }}
          </span>
          <span slot="action" slot-scope="text, row">
            <a-space>
              <a class="ant-dropdown-link" @click="out(row)">迁出</a>
            </a-space>
          </span>
        </a-table>
      </div>
	  <div style="margin-top: 24px">其他信息</div>
      <a-divider></a-divider>
	  <a-descriptions layout="vertical">
		  <a-descriptions-item label="婚姻状况">
			{{holderDetail.maritalStatus == 1 ? '未婚' : '已婚'}}
		  </a-descriptions-item>
		  <a-descriptions-item label="民族">
			{{holderDetail.nation}}
		  </a-descriptions-item>
		  <a-descriptions-item label="政治面貌">
			{{holderDetail.politicalOutlook == -1 ? '' : politicalOutlook[holderDetail.politicalOutlook-1] }}
		  </a-descriptions-item>
		  <a-descriptions-item label="从事职业">
			{{holderDetail.occupation}}
		  </a-descriptions-item>
		  <a-descriptions-item label="工作单位">
			{{holderDetail.employer}}
		  </a-descriptions-item>
		  <a-descriptions-item label="户口类型">
			{{holderDetail.accountType == 1 ? '农村' : '城市'}}
		  </a-descriptions-item>
		  <a-descriptions-item label="工作单位地址">
			{{holderDetail.employerAddress}}
		  </a-descriptions-item>
	  </a-descriptions>
	  <a-descriptions layout="vertical" :column="1">
		  <a-descriptions-item label="备注">
			{{holderDetail.remarks}}
		  </a-descriptions-item>
	  </a-descriptions>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="onClose">
          关闭
        </a-button>
      </div>
    </a-drawer>
	<a-modal title="批量导入操作成功" :visible="uploadVisible" @cancel="uploadVisible = false" :footer="null">
			<div>以下为导入失败的用户</div>
			<p v-for="(item,index) in uploadResult" :key="index">
				{{item}}
			</p>
	</a-modal>
  </div>
</template>

<script>
import store from "@/store";
import {
	columns,
	pagination,
	searchForm,
	rules,
	columns_house,
	politicalOutlook,
} from "./depend/config.js";
import { estate } from "@/api/basic/estate";
import {
	residentList,
	moveIn,
	moveOut,
	findTenantByEstateId,
	residentDetail,
} from "@/api/basic/resident";
import addForm from "./depend/addForm.vue";
export default {
	components: {
		addForm,
	},
	data() {
		return {
			columns_house,
			editId: null,
			inform: {
				estateId: undefined,
				residentId: undefined,
				identity: undefined,
				linkUserId: undefined,
			},
			estateArr: [],
			estateData: [],
			rules,
			show: {
				add: false,
				in: false,
				detail: false,
			},
			pagination,
			searchForm,
			selectedRowKeys: [],
			// 分页
			//   列
			columns,
			holderInfo: {},
			//   数据
			tableData: [],
			table_house: [],
			holderDetail: {},
			imgUrl: '', //住户图片
			idCardFront: '',
			idCardBack: '',
			politicalOutlook: politicalOutlook,
			options: {
				identity: [
					{ id: 1, name: "业主" },
					{ id: 2, name: "业主亲属" },
					{ id: 3, name: "租户" },
					{ id: 4, name: "租户亲属" },
				],
				tenant: [],
			},
			// 上传
			uploadHeaders: {
				"manage-login-token": store.getters.getToken,
			},
			//上传对话框
			uploadVisible: false,
			uploadResult: [],
		};
	},
	mounted() {
		this.getData();
		this.getEstate();
	},
	methods: {
		async getData() {
			const res = await residentList({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				...this.searchForm,
			});
			this.tableData = res.data.rows;
			this.pagination.total = res.data.total;
		},
		houseManage(data) {
			this.holderInfo = data;
			this.table_house = data.residentListEstateVoList;
			residentDetail({id: data.id}).then(res => {
				this.holderDetail = res.data;
				if(res.data.residentImgUrls.length != 0) {
					this.imgUrl = this.$ImgUrl(res.data.residentImgUrls[0].url)
				} else {
					this.imgUrl = ''
				}
				if(res.data.idCardFrontImgUrls.length != 0) {
					this.idCardFront = this.$ImgUrl(res.data.idCardFrontImgUrls[0].url)
				} else {
					this.idCardFront = ''
				}
				if(res.data.idCardBackImgUrls.length != 0) {
					this.idCardBack = this.$ImgUrl(res.data.idCardBackImgUrls[0].url)
				} else {
					this.idCardBack = ''
				}
			})
			this.show.detail = true;
		},
		// 所有房屋
		async getEstate() {
			let res = await estate();
			this.estateData = res.data;
			this.estateDisable(this.estateData);
		},
		estateDisable(arr) {
			arr.forEach((item, index) => {
				if (!item.manageEstateTypeName && item.childList.length === 0) {
					arr[index].disabled = true;
				} else if (item.manageEstateTypeName || item.childList.length > 0) {
					arr[index].disabled = false;
				} else {
					this.estateDisable(item.childList);
				}
			});
		},
		// 重置
		reset() {
			this.searchForm = {
				keyword: "",
				sex: undefined,
			};
			this.getData();
		},
		// 编辑住户
		edit(data) {
			this.editId = data.id;
			this.show.add = true;
		},
		// form提交成功的回调
		close() {
			this.show.add = false;
			this.editId = null;
		},
		// form提交成功的回调
		success() {
			this.getData();
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		async insubmit() {
			// 迁入提交
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					let res = await moveIn(this.inform);
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.getData();
						this.inclose();
					} else {
						this.$message.error(res.msg);
					}
				}
			});
		},
		onClose() {
			this.show.detail = false;
		},
		inclose() {
			this.$refs.ruleForm.resetFields();
			this.estateArr = [];
			this.show.in = false;
		},
		out(data) {
			// 迁出
			this.$confirm({
				title: "是否迁出",
				// okText:'删除',
				// cancelText:'取消',
				icon: "logout",
				onOk: async () => {
					let res = await moveOut({
						estateId: data.id,
						residentId: this.holderInfo.id,
					});
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.getData();
						this.onClose();
					} else {
						this.$message.error(res.msg);
					}
				},
			});
		},
		onChange(value, selectedOptions) {
			if (value.length > 0) {
				this.inform.estateId = value[value.length - 1];
				findTenantByEstateId({ estateId: this.inform.estateId }).then((res) => {
					this.options.tenant = res.data;
				});
			} else {
				this.inform.estateId = undefined;
			}
		},
		//切换居住房屋
		handlerBuilding(index) {
		},
		//上传
		changeFile(data) {
			if(data.file.status == "done") {
				if(data.file.response.success == false) {
					this.$message.error(data.file.response.msg);
				} else {
					this.$message.success('操作成功');
					if(data.file.response.data) {
						this.uploadResult = data.file.response.data;
						this.uploadVisible = true;
					}
					this.getData();
				}
			}
		},
		//模版下载
		modelDownload() {
			window.open('https://saas.kaidalai.cn/excelModel/住户导入模版.xlsx');
		}
	},
	computed: {
		// 是否选择selection
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>

<style lang="less" scoped>
/deep/.ant-upload.ant-upload-select-picture-card {
  width: 240px;
  height: 160px;
}
/deep/.avatar-uploader > .ant-upload {
  width: 240px;
  height: 160px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
