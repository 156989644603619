export const form = {
	id:null,
	manageEstateId: undefined,
	type:'',
	identity:undefined,
	name:undefined,
	tel:undefined,
	sex:undefined,
	idCard:undefined,
	checkTime:undefined,
	domicile:undefined,
	emergencyContact:undefined,
	emergencyContactNumber:undefined,
	maritalStatus:undefined,
	nation:undefined,
	politicalOutlook:undefined,
	accountType:undefined,
	occupation:undefined,
	employer:undefined,
	employerAddress:undefined,
	remarks:undefined,
	idCardFrontImgUrls:undefined,
	idCardBackImgUrls:undefined,
	residentImgUrls:undefined,
	ownerName:undefined,
	ownerTel:undefined,
	tenantName:undefined,
	tenantTel:undefined,
	ownerName:undefined, //业主姓名 
	ownerTel:undefined, //业主电话
	tenantName: undefined,//租户姓名
	tenantTel: undefined,//租户电话
}

const handleConfirm = (rule,value,callback) => {
	if(!value){
		callback();
	  }else{
		// 正则判断失败抛出错误，否则直接callback()
		if(!/(^\d{18}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)){
		  callback(new Error("身份证格式不正确!"));
		}else{
		  callback();
		}
	}
}

export const rules = {
	manageEstateId:[{required:true,message:'请选择房屋',trigger:'change'}],
	identity:[{required:true,message:'请选择身份',trigger:'change'}],
	name:[{required:true,message:'请输入姓名',trigger:'blur'}],
	tel:[{required:true,message:'请输入电话',trigger:'blur'}],
	sex:[{required:true,message:'请选择性别',trigger:'change'}],
	idCard:[{required:true,message:'请输入身份证号',trigger:'blur'},{validator: (rule,value,callback) => handleConfirm(rule,value,callback)}],
	ownerName:[{required:true,message:'请输入业主姓名',trigger:'blur'}],
	ownerTel:[{required:true,message:'请输入业主电话',trigger:'blur'}],
	tenantName:[{required:true,message:'请输入租户姓名',trigger:'blur'}],
	tenantTel:[{required:true,message:'请输入租户电话',trigger:'blur'}],
}
export const options = {
	identity:[
		{ id:1, name:'业主' },
		{ id:2, name:'业主亲属' },
		{ id:3, name:'租户' },
		{ id:4, name:'租户亲属' },
	],
	sex:[
		{ id:1, name:'男' },
		{ id:2, name:'女' },
		{ id:3, name:'保密' },
	],
	maritalStatus:[
		{ id:1, name:'未婚' },
		{ id:2, name:'已婚' },
	],
	politicalOutlook:[
		{ id:1, name:'群众' },
		{ id:2, name:'中共党员' },
		{ id:3, name:'共青团' },
		{ id:4, name:'民革党员' },
		{ id:5, name:'民盟盟员' },
		{ id:6, name:'民建会员' },
		{ id:7, name:'民进会员' },
		{ id:8, name:'农工党党员' },
		{ id:9, name:'致公党党员' },
		{ id:10, name:'九三学社社员' },
		{ id:11, name:'台盟盟员' },
		{ id:12, name:'无党派人士' },
	],
	accountType:[
		{ id:1, name:'农村' },
		{ id:2, name:'城市' },
	]
}
// 请求头
import store from "@/store";
export const uploadHeaders = {
	"manage-login-token": store.getters.getToken,
}