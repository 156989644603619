import { render, staticRenderFns } from "./addForm.vue?vue&type=template&id=6e1e5260&scoped=true&"
import script from "./addForm.vue?vue&type=script&lang=js&"
export * from "./addForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e1e5260",
  null
  
)

export default component.exports