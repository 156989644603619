<template>
  <div>
    <!-- add -->
    <a-drawer
      :title="title"
      :width="720"
      :visible="show"
      :body-style="{ paddingBottom: '80px' }"
      @close="addClose"
    >
      <div class="drawer-content">
        基本信息
        <a-divider></a-divider>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          layout="vertical"
        >
          <a-row>
            <a-col :span="8" v-if="form.id===null">
              <a-form-model-item label="关联房屋" prop="manageEstateId">
                <a-cascader
                  :options="estateData"
                  v-model="estateArr"
                  :field-names="{ label: 'name', value: 'id', children: 'childList' }"
                  placeholder="楼栋/单元/房间号"
                  @change="onChange"
                  style="width: 90%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="8" v-if="form.id===null">
              <a-form-model-item label="房屋类型" prop="type">
                <a-input
                  v-model="form.type"
                  placeholder="请先选择关联房屋"
                  disabled
                  style="width: 90%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="8" v-if="form.id===null">
              <a-form-model-item label="住户身份" prop="identity">
                <a-select v-model="form.identity" placeholder="请选择住户身份" style="width: 90%">
                  <a-select-option v-for="item in options.identity" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <!--  -->
            <!-- <div v-if="form.identity == 2 || form.identity == 3">
              <a-col :span="12">
                <a-form-model-item label="业主姓名" prop="ownerName">
                  <a-input placeholder="请输入姓名" v-model="form.ownerName" style="width: 90%" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="业主电话" prop="ownerTel">
                  <a-input placeholder="请输入电话" v-model="form.ownerTel" style="width: 90%" />
                </a-form-model-item>
              </a-col>
            </div> -->
            <!-- <div v-if="form.identity == 4">
              <a-col :span="12">
                <a-form-model-item label="租户姓名" prop="tenantName">
                  <a-input placeholder="请输入姓名" v-model="form.tenantName" style="width: 90%" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="租户电话" prop="tenantTel">
                  <a-input placeholder="请输入电话" v-model="form.tenantTel" style="width: 90%" />
                </a-form-model-item>
              </a-col>
            </div> -->
            <!--  -->
            <a-col :span="12">
              <a-form-model-item label="姓名" prop="name">
                <a-input placeholder="请输入姓名" v-model="form.name" style="width: 90%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="手机号" prop="tel">
                <a-input placeholder="请输入手机号" v-model="form.tel" style="width: 90%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="性别" prop="sex">
                <a-select placeholder="请选择性别" v-model="form.sex" style="width: 90%" >
                  <a-select-option v-for="item in options.sex" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="身份证号" prop="idCard">
                <a-input placeholder="请输入身份证号" v-model="form.idCard" style="width: 90%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="入住时间" prop="checkTime">
                <a-date-picker  v-model="form.checkTime" value-format="YYYY-MM-DD HH:mm:ss"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="户籍所在地" prop="domicile">
                <a-input placeholder="户籍所在地" v-model="form.domicile" style="width: 90%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              身份证头像照
              <!-- head -->
              <a-upload
                list-type="picture-card"
                :action="`${$upload}`"
                :headers="uploadHeaders"
                accept='.jpg,.JPG,.png,.PNG,.jpeg,.JPEG'
                @preview="handlePreview"
                @change="headChange"
                :file-list="headList"
                :beforeUpload="beforeUpload"
              >
                <div v-if="headList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    上传照片
                  </div>
                </div>
              </a-upload>
            </a-col>
            <a-col :span="12">
              身份证徽面照
              <!-- back -->
              <a-upload
                list-type="picture-card"
                :action="`${$upload}`"
                :headers="uploadHeaders"
                accept='.jpg,.JPG,.png,.PNG,.jpeg,.JPEG'
                @preview="handlePreview"
                @change="backChange"
                :file-list="backList"
                :beforeUpload="beforeUpload"
              >
                <div v-if="backList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    上传照片
                  </div>
                </div>
              </a-upload>
            </a-col>
            <div v-show=" form.identity === 4">
              关联信息
              <a-divider></a-divider>
            </div>
            <div v-if="form.identity === 2">
              <!-- <a-col :span="12">
                <a-form-model-item label="业主姓名" prop="ownerName">
                  <a-input placeholder="请输入业主姓名" v-model="form.ownerName" style="width: 90%" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="业主电话" prop="ownerTel">
                  <a-input placeholder="请输入业主电话" v-model="form.ownerTel" style="width: 90%" />
                </a-form-model-item>
              </a-col> -->
            </div>
            <div v-else-if="form.identity === 4">
              <a-col :span="12">
                <a-form-model-item label="租户姓名" prop="tenantName">
                  <a-input placeholder="请输入租户姓名" v-model="form.tenantName" style="width: 90%" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="租户电话" prop="tenantTel">
                  <a-input placeholder="请输入租户电话" v-model="form.tenantTel" style="width: 90%" />
                </a-form-model-item>
              </a-col>
            </div>
            联系信息
            <a-divider></a-divider>
            <a-col :span="12">
              <a-form-model-item label="紧急联系人" prop="emergencyContact">
                <a-input placeholder="请输入紧急联系人" v-model="form.emergencyContact" style="width: 90%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                label="紧急联系人电话"
                prop="emergencyContactNumber"
              >
                <a-input
                  placeholder="请输入紧急联系人电话"
                  style="width: 90%"
                  v-model="form.emergencyContactNumber"
                />
              </a-form-model-item>
            </a-col>
            其他信息
            <a-divider></a-divider>
            <a-col :span="12">
              <a-form-model-item label="婚姻状况" prop="maritalStatus">
                <a-select v-model="form.maritalStatus" placeholder="请选择婚姻状况" style="width: 90%">
                  <a-select-option v-for="item in options.maritalStatus" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="民族" prop="nation">
                <a-input placeholder="请输入民族" v-model="form.tenantTel" style="width: 90%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="政治面貌" prop="politicalOutlook">
                <a-select placeholder="请选择婚姻状况" v-model="form.politicalOutlook" style="width: 90%" >
                  <a-select-option v-for="item in options.politicalOutlook" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="户口类型" prop="accountType">
                <a-select placeholder="请选择户口类型" v-model="form.accountType" style="width: 90%" >
                  <a-select-option v-for="item in options.accountType" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="从事职业" prop="occupation">
                <a-input placeholder="请输入从事职业" v-model="form.occupation" style="width: 90%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="工作单位" prop="employer">
                <a-input placeholder="请输入工作单位" v-model="form.employer" style="width: 90%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="工作单位地址" prop="employerAddress">
                <a-input placeholder="请输入工作单位地址" v-model="form.employerAddress" style="width: 90%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              住户照片
              <!-- pic -->
              <a-upload
                list-type="picture-card"
                :action="`${$upload}`"
                :headers="uploadHeaders"
                accept='.jpg,.JPG,.png,.PNG,.jpeg,.JPEG'
                @preview="handlePreview"
                @change="picChange"
                :beforeUpload="beforeUpload"
                :file-list="picList"
              >
                <div v-if="picList.length < 4">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    上传照片
                  </div>
                </div>
              </a-upload>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="备注" prop="remarks">
                <a-textarea placeholder="请输入备注" v-model="form.remarks" style="width: 90%" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      <div class="drawer-footer">
        <a-button
          :style="{ marginRight: '8px' }"
          @click="addClose"
        >
          关闭
        </a-button>
        <a-button type="primary" @click="submit"> 提交 </a-button>
      </div>
    </a-drawer>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { form, rules, options, uploadHeaders } from "./form.js"
import { estate } from '@/api/basic/estate'
import { residentInsert, residentUpdate, residentInfo } from '@/api/basic/resident'
function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
}
export default {
	props:{
		show:{
			default:false,
			type:Boolean,
		},
		editId:Number,
	},
	data() {
		return {
			form,
			rules,
			options,
			loading: false,
			estateArr:[],
			estateData:[],
			title:'新增住户',
			previewVisible:false,
			uploadHeaders,
			previewImage:'',
			headList: [],
			backList: [],
			picList: [],
		}
	},
	mounted() {
		this.getEstate()
	},
	methods: {
		async getEstate(){
			let res = await estate()
			this.estateData = res.data
			this.estateDisable(this.estateData)
		},
		estateDisable(arr){
			arr.forEach((item,index)=>{
				if(!item.manageEstateTypeName && item.childList.length === 0){
					arr[index].disabled = true
				}else if(item.manageEstateTypeName || item.childList.length > 0){
					arr[index].disabled = false
				}else{
					this.estateDisable(item.childList)
				}
			})
		},
		submit(){
			// 照片
			// 头像
			let head = []
			for(let item of this.headList){
				// 新增的照片
				if(item.response){
					head.push(item.response.data)
				}else{
				// 已添加的照片
					head.push(item.url.split('/')[item.url.split('/').length-1])
				}
			}
			this.form.idCardFrontImgUrls = head
			// 徽面
			let back = []
			for(let item of this.backList){
				// 新增的照片
				if(item.response){
					back.push(item.response.data)
				}else{
				// 已添加的照片
					back.push(item.url.split('/')[item.url.split('/').length-1])
				}
			}
			this.form.idCardBackImgUrls = back
			// 照片
			let pic = []
			for(let item of this.picList){
				// 新增的照片
				if(item.response){
					pic.push(item.response.data)
				}else{
				// 已添加的照片
					pic.push(item.url.split('/')[item.url.split('/').length-1])
				}
			}
			this.form.residentImgUrls = pic
			this.$refs.ruleForm.validate(async valid => {
				if(valid){
					if(this.title === '新增住户'){
						let res = await residentInsert(this.form)
						if(res.code === 200){
							this.$message.success(res.msg)
							this.success()
							this.addClose()
						}else{
							this.$message.error(res.msg)
						}
					}else if(this.title === '修改住户信息'){
						let res = await residentUpdate(this.form)
						if(res.code === 200){
							this.$message.success(res.msg)
							this.success()
							this.addClose()
						}else{
							this.$message.error(res.msg)
						}
					}
				}
			})
		},
		beforeUpload(f, l) {
			const isLt50M = f.size / 1024 / 1024 < 50;
			if (!isLt50M) {
				this.$message.error(f.name + "文件大小超出限制，请修改后重新上传");
				return false;
			} else {
				return true;
			}
		},
		addClose(){
			this.$refs.ruleForm.resetFields();
			this.$emit('close')
			this.estateArr = []
			this.headList =  []
			this.backList =  []
			this.picList = []
		},
		success(){
			this.$emit('success')
		},
		async handlePreview(file) {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			this.previewImage = file.url || file.preview;
			this.previewVisible = true;
		},
		headChange(info){
			this.headList = info.fileList
		},
		backChange(info){
			this.backList = info.fileList
		},
		picChange(info){
			this.picList = info.fileList
		},
		handleCancel() {
			this.previewVisible = false;
		},
		onChange(value, selectedOptions){
			if(value.length > 0){
				this.form.manageEstateId = value[value.length - 1];
				this.form.type = selectedOptions[selectedOptions.length - 1].manageEstateTypeName;
			}else{
				this.form.manageEstateId = undefined;
				this.form.type = ''
			}
		},
	},
	watch: {
		editId(val){
			if(val){
				this.form.id = val
				this.title = '修改住户信息'
				residentInfo({id:val}).then(res=>{
					this.form.name = res.data.name
					this.form.tel = res.data.tel
					this.form.sex	 = res.data.sex
					this.form.idCard = res.data.idCard
					this.form.checkTime = res.data.checkTime
					this.form.domicile = res.data.domicile
					this.form.emergencyContact = res.data.emergencyContact
					this.form.emergencyContactNumber = res.data.emergencyContactNumber
					this.form.maritalStatus = res.data.maritalStatus
					this.form.nation = res.data.nation
					this.form.politicalOutlook = res.data.politicalOutlook
					this.form.accountType = res.data.accountType
					this.form.occupation = res.data.occupation
					this.form.employer = res.data.employer
					this.form.employerAddress = res.data.employerAddress
					this.form.remarks = res.data.remarks
					// idCardFrontImgUrls
					// idCardBackImgUrls
					// residentImgUrls
					const head = []
					for(let item of res.data.idCardFrontImgUrls){
						let obj = {
							name:item.url.split('_')[0] +'.'+ item.url.split('.')[1],
							url: this.$ImgUrl(item.url),
							uid:item.url.split('_')[1],
							status:'done',
							thumbUrl: this.$ImgUrl(item.url),
						}
						head.push(obj)
					}
					this.headList = head
					const back = []
					for(let item of res.data.idCardBackImgUrls){
						let obj = {
							name:item.url.split('_')[0] +'.'+ item.url.split('.')[1],
							url: this.$ImgUrl(item.url),
							uid:item.url.split('_')[1],
							status:'done',
							thumbUrl: this.$ImgUrl(item.url),
						}
						back.push(obj)
					}
					this.backList = back
					const pic = []
					for(let item of res.data.residentImgUrls){
						let obj = {
							name:item.url.split('_')[0] +'.'+ item.url.split('.')[1],
							url: this.$ImgUrl(item.url),
							uid:item.url.split('_')[1],
							status:'done',
							thumbUrl: this.$ImgUrl(item.url),
						}
						pic.push(obj)
					}
					this.picList = pic
				})
			}else{
				this.form.id = null
				this.title = '新增住户'
			}
		}
	}
};
</script>
<style lang="less" scoped>

</style>