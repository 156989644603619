/**
 * 配置
 * 表格列
 */
export const columns = [
	{
		title: "导入编号",
		dataIndex: "id",
		width: 100
	},
	{
		title: "住户姓名",
		dataIndex: "name",
		width: 100
	},
	{
		title: "住户手机号",
		dataIndex: "tel",
		width: 160
	},
	{
		title: "居住房屋",
		dataIndex: "build",
		scopedSlots: { customRender: "build" },
		width: 200
	},
	{
		title: "住户身份",
		dataIndex: "ids",
		width: 120,
		scopedSlots: { customRender: "ids" },
	},
	{
		title: "房屋类型",
		dataIndex: "type",
		width: 120,
		scopedSlots: { customRender: "type" },
	},
	{
		title: "住户性别",
		dataIndex: "sex",
		width: 120,
		customRender:function(sex){
			switch (sex) {
			case 1:
				return '男'
			case 2:
				return '女'
			case 3:
				return '保密'
			default:
				break;
			}
		}
	},
	{
		title: "住户身份证号",
		dataIndex: "idCard",
	},
	{
		title: "创建时间",
		dataIndex: "createDate",
		customRender:function(createDate){
			let date = new Date(createDate).toLocaleString();
			return date
		}
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const columns_house = [
	{
		title: "房屋名称",
		dataIndex: "manageBuildingName",
		scopedSlots: { customRender: "name" },
		width: "44%",
	},
	{
		title: "房屋类型",
		dataIndex: "manageEstateTypeName",
		width: "20%",
	},
	{
		title: "住户身份",
		dataIndex: "identity",
		customRender:function(identity){
			switch (identity) {
			case 1:
				return '业主'
			case 2:
				return '业主亲属'
			case 3:
				return '租户'
			case 4:
				return '租户亲属'
			default:
				break;
			}
		},width: "20%",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
// 搜索项
export const searchForm = {
	keyword: "",
	sex: undefined,
}
// 分页
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}
export const rules = {
	estateId:[{required:true,message:'请选择房屋',trigger:'change'}],
	identity:[{required:true,message:'请选择身份',trigger:'change'}],
	linkUserId	:[{required:true,message:'请输入姓名',trigger:'change'}],
}
export const politicalOutlook = ["群众","中共党员","共青团","民革党员","民盟盟员",
"民建会员","民进会员","农工党党员","致公党党员","九三学社社员","台盟盟员","无党派人士",]